<template>
  <main class="about" v-if="about">
    <div v-if="$store.state.info.bio" class="about-item">
      <div class="about-title">Biography</div>
      <div class="about-content" v-html="about.text"></div>
    </div>
    <div v-if="$store.state.info.contact" class="about-item">
      <div class="about-title">Contact</div>
      <div class="" v-html="about.contact"></div>
    </div>
    <div v-if="$store.state.info.newsletter" class="about-item">
      <div class="about-title">Newsletter</div>
      <form
        action="https://gmail.us3.list-manage.com/subscribe/post?u=34e6c114cff7f124c7a24f8e0&amp;id=742d62daab"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        class="validate about-newsletter"
        target="_blank"
        novalidate
      >
        <input
          type="email"
          value=""
          v-model="email"
          name="EMAIL"
          class="email"
          placeholder="Email..."
          required
        />
        <div style="position: absolute; left: -5000px" aria-hidden="true">
          <input
            type="text"
            name="b_f61bc318c8b6d3429d69fea3b_fe519af25c"
            tabindex="-1"
            value=""
          />
        </div>
        <input
          v-if="email.length > 0"
          type="submit"
          value="Subscribe"
          name="button"
          class="button smallcaps"
        />
      </form>
    </div>
    <div
      v-if="$store.state.info.news && about.news.length > 0"
      class="about-item"
    >
      <div class="about-title">News</div>
      <div
        v-for="(news, index) in about.news"
        :key="index"
        class="about-news"
        v-html="news.text"
      ></div>
    </div>
    <div
      class="about-item"
      v-if="$store.state.info.press && about.press.length > 0"
    >
      <div class="about-title">Selected Press</div>
      <div
        v-for="(press, index) in about.press"
        :key="'p-' + index"
        class="about-content"
      >
        <a v-if="press.type === 'link'" :href="press.link" target="_blank">{{
          press.title
        }}</a>
        <a v-if="press.type === 'file'" :href="press.file" target="_blank">{{
          press.title
        }}</a>
      </div>
    </div>

    <div v-for="key in cv" :key="key.id" class="about-item">
      <template v-if="$store.state.info[parseKey(key.key)]">
        <div class="about-title">{{ fixTitle(key.key) }}</div>
        <div v-for="year in key.years" :key="year.id" class="about-cv">
          <div class="about-year">
            {{ year.year }}
          </div>
          <div class="about-entries">
            <div
              v-for="project in year.projects"
              :key="project.id"
              v-html="project.title"
            ></div>
          </div>
        </div>
      </template>
    </div>

    <div v-if="$store.state.info.impressum" class="about-item">
      <div class="about-title">Impressum</div>
      <div v-html="about.impressum"></div>
    </div>
  </main>
</template>

<script>
import { groupBy, orderBy, map } from "lodash";
export default {
  name: "about",
  data() {
    return {
      email: "",
    };
  },
  computed: {
    about() {
      return this.$store.state.pages;
    },
    cv() {
      let groupCv = groupBy(this.about.cv, (project) => project.type);
      let mapCv = map(groupCv, function (value, key) {
        let years = groupBy(value, (entry) => entry.year);
        let mapYears = map(years, (value, key) => ({
          year: key,
          projects: value,
        }));
        var sortedYears = orderBy(mapYears, ["year"], ["desc"]);
        return {
          key: key,
          years: sortedYears,
        };
      });
      return mapCv;
    },
  },
  methods: {
    parseKey(key) {
      var replaced = key.replace(/ /g, "_");
      return replaced;
    },
    fixTitle(title) {
      var replaced = title.replace("solo", "selected");
      replaced = replaced.replace("group", "selected group");
      return replaced;
    }
  },
};
</script>

<style lang="scss">
.about {
  box-sizing: border-box;
  width: 100%;
  min-height: 50%;
  background: white;
  column-count: 2;
  column-gap: calc(4 * var(--lh));
  @media only screen and (max-width: 768px) {
    column-count: 1;
  }
  .about-item {
    margin-bottom: var(--lh);
    break-inside: avoid;
    .about-newsletter {
      display: flex;
      input {
        font-family: "Goldman", sans-serif;
        font-size: var(--fs);
        border: 0;
        border-radius: 0;
        outline: none;
        flex: 1 0 auto;
        margin-right: var(--lh);
        transition: border 0.5s;
      }
      input[type="submit"] {
        flex: 0 0 auto;
        border: 0;
        background: rgba(0, 0, 0, 0.05);
        transition: background 0.5s;
        margin-right: 0;
        cursor: pointer;
        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }

    p {
      margin-bottom: var(--lh);
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .about-title {
      font-variant: small-caps;
      text-transform: lowercase;
      letter-spacing: 0.05vw;
      margin-bottom: var(--lh);
    }
    .about-news {
      margin-bottom: var(--lh);
    }
    .about-cv {
      display: flex;
      margin-bottom: var(--lh);
      .about-year {
        padding-right: calc(2 * var(--lh));
      }
    }
  }
}
</style>
